import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { API } from '../../API_Service';
import {useCookies} from 'react-cookie'


function TaskList(props) {
    
    const [token] = useCookies(['higel-token'])
    const [currentMagnet] = useCookies(['currentMagnet']);


    const taskClicked = todos => evt => {
        props.taskClicked(todos)
    }

    const editClicked = todos => {
        props.editClicked(todos)
    }

    const deleteClicked = todo => {
        API.deleteTask(todo.id, token)
        .then(() => props.deleteClicked(todo))
        .catch(err => console.error(err))
    }

    return(
        <div className='todo-list'>
              {props.todos && props.todos.map(todos => {
              return <React.Fragment>
                        <p key={todos.id} onMouseOver={taskClicked(todos)}> 
                            {todos.title} 
                            &nbsp;<FontAwesomeIcon icon={faEdit} onClick={() => editClicked(todos)}/>
                            &nbsp;<FontAwesomeIcon icon={faTrash} onClick={() => deleteClicked(todos)}/>
                        </p>
                    </React.Fragment>
              })}
          </div>
    )
}

export default TaskList;