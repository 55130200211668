
const route ='https://refridgeratordoor-b97c7e77f041.herokuapp.com/'
// const route ='http://127.0.0.1:8000/'


export class API{ 

    //AUTH Page
    static loginUser(body) {
    return fetch(`${route}api/authenticate/`,{
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body)
        })
        .then(resp  => resp.json(resp))
    }

    //AUTH Page
    static registerUser(body) {
    return fetch(`${route}api/users/`,{
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body)
        }).then(resp  => resp.json(resp))
    }

    static updateTask(todos, body, token) {
    return fetch(`${route}api/todos/${todos.id}/?group=${token['currentMagnet']}`,{
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token['higel-token']}`
        },
        body: JSON.stringify(body)
        }).then(resp  => resp.json(resp))
    }
    static createTask(body, token) {
        return fetch(`${route}api/todos/?group=${token['currentMagnet']}`,{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token['higel-token']}`
            },
            body: JSON.stringify(body)
            }).then(resp  => resp.json(resp))
        }
    static deleteTask(todo_id, token) {
        return fetch(`${route}api/todos/${todo_id}/?group=${token['currentMagnet']}`,{
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token['higel-token']}`
            },
            })
        }
    static getCalendarEvents(body, token) {
        return fetch(`${route}api/calendar/?group=${token['currentMagnet']}`,{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token['higel-token']}`
            },
            body: JSON.stringify(body)
            }).then(resp  => resp.json(resp))
        }


        ///////THIS WORKS///////
    static createEvent(body, token){
        return fetch(`${route}api/calendar/`,{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token['higel-token']}`
            },
            body: JSON.stringify(body)
            }).then(resp  => resp.json(resp))
    }

    static updateShopping(shopping,body, token) {
    return fetch(`${route}api/shopping/${shopping.id}/?group=${token['currentMagnet']}`,{
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token['higel-token']}`
        },
        body: JSON.stringify(body)
        }).then(resp  => resp.json(resp))
    }
    static createShopping(body, token) {
        return fetch(`${route}api/shopping/`,{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token['higel-token']}`
            },
            body: JSON.stringify(body)
            }).then(resp  => resp.json(resp))
        }
    static deleteShopping(shopping_id, token) {
        return fetch(`${route}api/shopping/${shopping_id}/?group=${token['currentMagnet']}`,{
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token['higel-token']}`
            },
            })
        }
    
    static updateGrocery(grocery, body, token) {
    return fetch(`${route}api/grocery/${grocery.id}/?group=${token['currentMagnet']}`,{
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token['higel-token']}`
        },
        body: JSON.stringify(body)
        }).then(resp  => resp.json(resp))
    }
    static createGrocery(body, token) {
        return fetch(`${route}api/grocery/?group=${token['currentMagnet']}`,{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token['higel-token']}`
            },
            body: JSON.stringify(body)
            }).then(resp  => resp.json(resp))
        }
    static deleteGrocery(grocery_id, token) {
        return fetch(`${route}api/grocery/${grocery_id}/?group=${token['currentMagnet']}`,{
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token['higel-token']}`
            },
            })
        }
    static getUsers(group,token) {
        return fetch(`${route}api/users/?group=${group}`,{
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token['higel-token']}`
            },
            }).then(resp  => resp.json(resp))
    }

    static getFavs(group,token) {
        return fetch(`${route}api/favorite/?group=${group}`,{
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token['higel-token']}`
            },
            }).then(resp  => resp.json(resp))
    }
    static updateFavorites(favorites, body, token) {
        return fetch(`${route}api/favorite/${favorites.id}/?group=${token['currentMagnet']}`,{
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token['higel-token']}`
            },
            body: JSON.stringify(body)
            }).then(resp  => resp.json(resp))
        }
        static createFavorites(body, token) {
            return fetch(`${route}api/favorite/?group=${token['currentMagnet']}`,{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token['higel-token']}`
                },
                body: JSON.stringify(body)
                }).then(resp  => resp.json(resp))
            }
}