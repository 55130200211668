import React, { useState, useEffect, useContext } from 'react';
import '../App.css';
import TaskList from './subcomponents/TaskList';
import TaskDetail from './subcomponents/TaskDetail';
import TaskStatus from './subcomponents/Status';
import UpdateTask from './subcomponents/UpdateTask';
import MagnetChoice from './subcomponents/MagnetChoice';
import {useCookies} from 'react-cookie';

function App() {

  const route ='https://refridgeratordoor-b97c7e77f041.herokuapp.com/'
  // const route ='http://127.0.0.1:8000/'

  const [currentMagnet] = useCookies(['currentMagnet']);
  const [magnetID] = useCookies(['magnetID']);
  const [todos, setTodos] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null);
  const [editTask, setEditTask] = useState(null);
  const [token, setToken, deleteToken] = useCookies(['higel-token']);

  //// Tie in to group number////
  const [group] = useState(magnetID['magnetID']);

  useEffect( () =>{
    fetch(`${route}api/todos/?group=${currentMagnet['currentMagnet']}`,{
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token['higel-token']}`
      }
    })
    .then(resp  => resp.json())
    .then(resp => setTodos(resp))
    .catch(error => console.log(error))
  }, [token, currentMagnet, group])

  useEffect( () => {
    if(!token['higel-token']) window.location.href = '/';
  }, [token])

  const taskClicked = todos => {
    setSelectedTask(todos);
  }

  const loadTask = todos => {
    setSelectedTask(todos);
  }

  const editClicked = todos => {
    setEditTask(todos);
  }

  const updatedTask = prevTodos => {
     const updatedTodos = todos.map(t =>{
      if (t.id === prevTodos.id) {
        return prevTodos;
      }
        return t;
    })
    setTodos(updatedTodos);
    setEditTask(null);
  }

  const createTask = () => {
   setEditTask({title:'', description:'', group:group});
   setSelectedTask(null)
 }

  const newTask = todo => {
    const newTodos = [...todos,todo];
    setTodos(newTodos);
    setEditTask(null)
  }

  const deleteClicked = (todo) => {
    const updatedTodos = todos.filter(t => t.id !== todo.id);
    setTodos(updatedTodos);
  }

  return (
    <div className="App">
      <header className="App-header">
            <MagnetChoice />
            <h1>To-Do List</h1>
          </header>
      <TaskDetail todos={selectedTask} />
      { editTask ? 
          <UpdateTask todos={editTask} updatedTask={updatedTask} newTask={newTask}/>
          :
          null}
      <hr className='line'/>
      <div className='row'>
        <div className='column1'>
          <TaskList 
          todos={todos} 
          taskClicked={taskClicked} 
          editClicked={editClicked}
          deleteClicked={deleteClicked}/> 
          <button onClick={createTask}> Create task </button>
        </div>
        <div className='column2'>
          <TaskStatus todos={todos} updateTask={loadTask}/>
        </div>

      </div>
    </div>
  );
}

export default App;
