import React, { useState, useEffect } from 'react';
import {API} from '../../API_Service';
import { useCookies } from 'react-cookie';

function UpdateFavorites(props) {

    const [title, setTitle] = useState(props.favorites.title);
    const [category, setCategory] = useState(props.favorites.category);
    const [info, setInfo] = useState(props.favorites.store);
    const [token] = useCookies(['higel-token'])
    const [currentMagnet] = useCookies(['currentMagnet']);
    const [magnetID] = useCookies(['currentMagnetID']);

    //// Tie in to group number////
    const [group] = useState(magnetID['magnetID']);


    useEffect(() => {
        setTitle(props.favorites.title);
        setCategory(props.favorites.category);
        setInfo(props.favorites.info);
    }, [props.favorites]);

    const saveFavorites = () => {
        {API.updateFavorites(props.favorites, {title, category, info, group}, token)
        .then(resp => props.updatedFavorites(resp))
        .catch(err => console.log(err));
        };}

    const newFavorites = () => {
        {API.createFavorites({title, category, info, group}, token)
        .then(resp => props.newFavorites(resp))
        .catch(err => console.log(err));
        };}

    const cancelClicked = () => {
        props.cancelClick();
    }

    return(
        <div className='newFave'>
            <div>
            <label htmlFor="title">Title</label><br/>
            <input className='favSmall' type="text" name="title" id="title" value={title}  
                onChange={ evt => setTitle(evt.target.value)}/><br/>
            </div>
            <div>
            <label htmlFor="Category">Category</label><br/>
            <input className='favSmall' ype="text" name="Category" id="Category" value={category}  
                onChange={ evt => setCategory(evt.target.value)}/><br/>
            </div>
            <div>
            <label htmlFor="Info">Info</label><br/>
            <input className='favBig' type="text" name="Info" id="Info" value={info}  
                onChange={ evt => setInfo(evt.target.value)}/><br/>
            </div>
            {props.favorites.id ? 
            <div>
            <button onClick={ evt => saveFavorites()}> Save </button> 
            <button onClick={ evt => cancelClicked()}> Cancel </button> 
            </div>
            :
            <div>
            <button onClick={ evt => newFavorites()}> Create </button>
            <button onClick={ evt => cancelClicked()}> Cancel </button>
            </div>
            }
        </div>
    )
}

export default UpdateFavorites;