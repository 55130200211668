import React, {useState, createContext}from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.css';
import Auth from './Auth';
import App from './components/App';
import Home from './components/Home';
import Calendar from './components/Calendar';
import Shopping from './components/Shopping';
import Grocery from './components/Grocery';
import Favorites from './components/Favorites';
import reportWebVitals from './reportWebVitals';
import { Route, Routes, BrowserRouter } from 'react-router-dom'
import {CookiesProvider} from 'react-cookie';


function Router(){
  
  return (
  <React.StrictMode>
      <CookiesProvider>
        <BrowserRouter>
          <Routes>
          <Route path="/" element={<Auth />} />
          <Route path="/home" element={<Home />} />
          <Route path="/calendar" element={<Calendar />} />
          <Route path="/todo" element={<App />} />
          <Route path="/shopping" element={<Shopping />} />
          <Route path="/grocery" element={<Grocery />} />
          <Route path="/favorites" element={<Favorites />} />
          </Routes>
        </BrowserRouter>
      </CookiesProvider>
  </React.StrictMode>
  )
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Router />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
