// src/MyCalendar.js

import React, { useState, useEffect } from "react";

function CalendarControl(props) {
  const [month, setMonth] = useState((new Date().getMonth()));
  const [year, setYear] = useState((new Date().getFullYear()));
  
  const nextMonth = monthName => {
    const selectedMonth = month === 11 ? 0 : month + 1;
    if(selectedMonth === 0){nextYear(year)}else{console.log(year)}
    setMonth(selectedMonth);
    props.nextMonth(selectedMonth);
}

  const previousMonth = monthName => {
    const selectedMonth = month === 0 ? 11 : month-1;
    if(selectedMonth === 11){previousYear(year)}else{console.log(year)}
    setMonth(selectedMonth);
    props.previousMonth(selectedMonth)
}

  const nextYear = year => {
    const selectedYear = year + 1
    setYear(selectedYear);
    props.nextYear(selectedYear)
}

  const previousYear = year => {
    const selectedYear = year - 1
    setYear(selectedYear);
    props.previousYear(selectedYear)
}


  return (
    <React.Fragment>
      <div className="datesNavigator">
        <button onClick={() => previousYear(props.selectedYear)}> Previous Year </button>
        <button onClick={() => previousMonth(props.selectedMonth)}> Previous Month </button>
        <div className="date">{props.displayMonth}, {props.selectedYear}</div>
        <button onClick={() => nextMonth(props.selectedMonth)}> Next Month </button>
        <button onClick={() => nextYear(props.selectedYear)}> Next Year </button>
      </div>     
    </React.Fragment>
  );
}

export default CalendarControl;
