import React, { useState, useEffect } from 'react';

function ShoppingDetail(props) {

    return(
        <div className='todo-list'>
            {props.shopping && props.shopping.description}
        </div>
    )
}

export default ShoppingDetail;