import React, {useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare, faSquareCheck } from '@fortawesome/free-solid-svg-icons';
import { useCookies } from 'react-cookie';


function TaskStatus(props) {

    const [selectedClicked, setSelectedCheck] = useState([])
    const [token] = useCookies(['higel-token'])
    const [currentMagnet] = useCookies(['currentMagnet']);
    const [magnetID] = useCookies(['currentMagnetID']);

    //// Tie in to group number////
    const [group] = useState(magnetID['magnetID']);


    const checkClicked = todos => evt =>{
        setSelectedCheck(todos);
        todos.completed =!todos.completed;
        
        fetch(`https://refridgeratordoor-b97c7e77f041.herokuapp.com/api/todos/?group=${group}/${todos.id}/complete_task/`,{
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        },
        body: JSON.stringify({completed:todos.completed})
        })
        .then(() => getCheck(todos.id))
        .catch(error => console.log(error))
    }

    const getCheck = id =>{
        
        fetch(`https://refridgeratordoor-b97c7e77f041.herokuapp.com/api/todos/?group=${group}/${id}/`,{
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
        })
        .then(resp  => resp.json())
        .then(resp => props.updateTask(resp))
        .catch(error => console.log(error))
    }
      

    return(
        <div className='todo-status'>
              {props.todos && props.todos.map(todos => {
              return <React.Fragment>
                        <p>
                        {todos.completed === false ? <FontAwesomeIcon icon={faSquare} onClick={checkClicked(todos)}/> : 
                        <FontAwesomeIcon icon={faSquareCheck} style={{color: "Green",}} onClick={checkClicked(todos)}/>  }
                        </p>
                        <p></p>
                    </React.Fragment>
              })}
          </div>
    )
}

export default TaskStatus;



