import React, {useEffect, useState} from 'react';
import '../../CSS/MagnetChoice.css';
import { useCookies } from 'react-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse } from '@fortawesome/free-solid-svg-icons';



function MagnetChoice() {

  const [currentMagnet, setCurrentMagnet, deleteCurrentMagnet] = useCookies([]);
  const [magnetID, setMagnetID, deleteMagnetID] = useCookies([]);
  const [displayMagnet, setDisplayMagnet] = useState(currentMagnet['currentMagnet']);
  const [token, setToken, deleteToken] = useCookies(['higel-token']);

    //Sets dictionary because magnet names are for filters/display and id is for POST/PUT requests//
    var magnetDictionary = {}

    token['magnetName'].map( mag => {
      magnetDictionary[mag[0]] = mag[1]
    })
  
    // diplays the magnet choices. Need a use state to update the actual display state.
    const handleChange = (event) => { 
      setCurrentMagnet('currentMagnet', event.target.value);
      setMagnetID('magnetID', magnetDictionary[event.target.value])
      setDisplayMagnet(event.target.value); 
    }; 

    // use effect to log out
    useEffect( () => {
        if(!token['higel-token']) window.location.href = '/';
    }, [token])
  
    const logOut = () => {
      deleteToken(['higel-token']);
      deleteCurrentMagnet(['currentMagnet']);
    }

    const goHome = () => {
        window.location.href = '/home'
      }

return(
    <header className="Home-header">
        <div className='rowMC'>
            <div className='columnMC1'>
                <p className='logout' onClick={ () => logOut() }>Logout</p>
            </div>
            <div className='columnMC2'>
                <select className='magnetSelector' value={displayMagnet} onChange={handleChange}> 
                    {token['magnetName'].map( mag => 
                    <option key={mag[1]} value={mag[0]}>{mag[0]}</option>)}
                </select> 
            </div>
            <div className='columnMC3'>
                <FontAwesomeIcon icon={faHouse} onClick={goHome}/>
            </div>
        </div>
        {/* cycle through all the magnet names */}

    </header>
 )         

}

export default MagnetChoice;