import React, { useState, useEffect } from 'react';
import {API} from '../../API_Service';
import {useCookies} from 'react-cookie'

function UpdateTask(props) {

    const [title, setTitle] = useState(props.todos.title);
    const [description, setDescription] = useState(props.todos.description);
    const [token] = useCookies(['higel-token'])
    const [currentMagnet] = useCookies(['currentMagnet']);
    const [magnetID] = useCookies(['currentMagnetID']);

    //// Tie in to group number////
    const [group] = useState(magnetID['magnetID']);

    useEffect(() => {
        setTitle(props.todos.title);
        setDescription(props.todos.description);
    }, [props.todos]);

    const saveEntry = () => {
        API.updateTask(props.todos, {title, description, group}, token)
        .then(resp => props.updatedTask(resp))
        .catch(err => console.log(err));
        };

    const newEntry = () => {
        console.log(token['magnetToken'])
        API.createTask({title, description, group}, token)
        .then(resp => props.newTask(resp))
        .catch(err => console.log(err));
        };

    return(
        <div className='todo-list'>
            <label htmlFor="title">Title</label><br/>
            <input type="text" name="title" id="title" value={title}  
                onChange={ evt => setTitle(evt.target.value)}/><br/>
            <label htmlFor="description">Description</label><br/>
            <input type="text" name="description" id="description" value={description}  
                onChange={ evt => setDescription(evt.target.value)}/><br/>
            
            {props.todos.id ? 
            <button onClick={ evt => saveEntry()}> Save </button> 
            :
            <button onClick={ evt => newEntry()}> Create </button>
            }
        </div>
    )
}

export default UpdateTask;