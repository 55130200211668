import React, {useState} from "react";

import  "../../CSS/Calendar.css";

function CalendarGrid(props) {
    let calendarRow1 = []
    let calendarRow2 = []
    let calendarRow3 = []
    let calendarRow4 = []
    let calendarRow5 = []
    let calendarRow6 = []

    // need to add a method to track leap years
    let monthDays = [31, (props.selectedYear%4 > 0 ? 28:29), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
    let monthLength = monthDays[props.selectedMonth]

    // add a method to track the year
    let previousMonth = monthDays[(props.selectedMonth === 0 ? 11 : props.selectedMonth-1)]
    const firstDay = new Date(props.selectedYear, props.selectedMonth, 1).getDay()
    let w=(firstDay)
    
    //This reformats all django dates into JS date format
  props.calendarEvents.map(event => {
    const javascriptDate = new Date(event.startDate)
    const formattedDate = javascriptDate.toISOString().split('T')[0];
    event.startDate = formattedDate
    const javascriptDate2 = new Date(event.endDate)
    const formattedDate2 = javascriptDate2.toISOString().split('T')[0];
    event.endDate = formattedDate2
  })

    //It just works. Don't fuck with it. Acc is the accumulator, it adds everything into a new array. Event is the 
    //map function looping through components. Then it goes to find the events using a sub-loop the finds everything
    //already loaded into the new accumulator. If the data does not exist, then it pushes it, otherwise it moves on
    const uniqueEvents = props.calendarEvents.reduce((acc, event) => {
        const existingEvent = acc.find(
          e => e.startDate === event.startDate && e.createdBy === event.createdBy
        );
      
        if (!existingEvent) {
          acc.push(event);
        }
      
        return acc;
      }, []);
      
 

    //z is a method to eliminate the dates that are not in the calendar for the given month - i.e. removed the 31st and 1st of the previous/next month
    let z=null

    for (let i = 0; i <= 41; i++) {
        let z;
        let javascriptDate;
        let formattedDate;
        let generatedDate;
      
        if (i < firstDay) {
          javascriptDate = new Date(props.selectedYear, props.selectedMonth-1, previousMonth - (firstDay - i - 1));
          formattedDate = javascriptDate.toISOString().split('T')[0];
          generatedDate = formattedDate
          z = null;
          w = previousMonth - (firstDay - i - 1);
        } else if (i >= firstDay && i < monthLength + firstDay) {
          w = i - firstDay + 1;
          z = w;
          javascriptDate = new Date(props.selectedYear, props.selectedMonth-1, previousMonth - (firstDay - i - 1));
          formattedDate = javascriptDate.toISOString().split('T')[0];
          generatedDate = formattedDate

        } else if (i >= monthLength + firstDay) {
            w = i - monthLength - firstDay + 1
            z = null;
            javascriptDate = new Date(props.selectedYear, props.selectedMonth+1, (i - monthLength - firstDay + 1));
            formattedDate = javascriptDate.toISOString().split('T')[0];
            generatedDate = formattedDate
        }
      
        // This is an if statement that filters bazed on z in the loop below. Change title to "important"
        const eventsOnDay = props.calendarEvents.filter(event => event.startDate <= generatedDate && event.endDate >= generatedDate && event.important);

        // Using the filtering for the user display as well
        const usersOnDay = uniqueEvents.filter(event => event.startDate <= generatedDate && event.endDate >= generatedDate && event.important === false)   
        
        if (i <= 6){
            //map below will use z on the if function and return the information if true
            // W is the actual calendar date. Consider changing
        calendarRow1.push(
          <React.Fragment key={i}>
            <div className='rowDiv' onClick={() => props.clicked(props.selectedYear, props.selectedMonth, z)}>
            <ul className={'first'}>
                {w}
                {eventsOnDay.map(event => (
                    <div className='important' key={event.id}>{event.title.substring(0, 15)} - {event.createdBy}</div>
                ))}
                {usersOnDay.map(event => (
                    <div className={event.createdBy} key={event.id}>{event.createdBy}</div>
                ))}
            </ul>
            </div>
          </React.Fragment>
        );
      } else if (i > 6 && i <= 13 ){
        calendarRow2.push(
            <React.Fragment key={i}>
            <div className='rowDiv' onClick={() => props.clicked(props.selectedYear, props.selectedMonth, z)}>
              <ul className={'second'}>
                {w}
                {eventsOnDay.map(event => (
                    <div className='important' key={event.id}>{event.title.substring(0, 15)} - {event.createdBy}</div>
                ))}
                {usersOnDay.map(event => (
                    <div className={event.createdBy} key={event.id}>{event.createdBy}</div>
                ))}
              </ul>
              </div>
            </React.Fragment>
          );
      } else if (i > 13 && i <= 20 ){
        calendarRow3.push(
            <React.Fragment key={i}>
            <div className='rowDiv' onClick={() => props.clicked(props.selectedYear, props.selectedMonth, z)}>
              <ul className={'third'}>
                {w}
                {eventsOnDay.map(event => (
                    <div className='important' key={event.id}>{event.title.substring(0, 15)} - {event.createdBy}</div>
                ))}
                {usersOnDay.map(event => (
                    <div className={event.createdBy} key={event.id}>{event.createdBy}</div>
                ))}
              </ul>
              </div>
            </React.Fragment>
          );
      
      } else if (i > 20 && i <= 27 ){
        calendarRow4.push(
            <React.Fragment key={i}>
            <div className='rowDiv' onClick={() => props.clicked(props.selectedYear, props.selectedMonth, z)}>
              <ul className={'fourth'}>
                {w}
                {eventsOnDay.map(event => (
                    <div className='important' key={event.id}>{event.title.substring(0, 15)} - {event.createdBy}</div>
                ))}
                {usersOnDay.map(event => (
                    <div className={event.createdBy} key={event.id}>{event.createdBy}</div>
                ))}
              </ul>
              </div>
            </React.Fragment>
          );
      
      } else if (i > 27 && i <= 34 ){
        calendarRow5.push(
            <React.Fragment key={i}>
            <div className='rowDiv' onClick={() => props.clicked(props.selectedYear, props.selectedMonth, z)}>
              <ul className={'fifth'}>
                {w}
                {eventsOnDay.map(event => (
                    <div className='important' key={event.id}>{event.title.substring(0, 15)} - {event.createdBy}</div>
                ))}
                {usersOnDay.map(event => (
                    <div className={event.createdBy} key={event.id}>{event.createdBy}</div>
                ))}
              </ul>
              </div>
            </React.Fragment>
          );
      } else {
        //If statement to catch any month scenarios where a six row is not needed
        if (firstDay <=4 && monthLength ===31)
        {calendarRow6 = null}
        else if (firstDay <=5 && monthLength ===30)
        {calendarRow6 = null}
        else if (monthLength <= 29)
        {calendarRow6 = null}
        else if (i > 34 && i <= 41) {
        calendarRow6.push(
            <React.Fragment key={i}>
            <div className='rowDiv' onClick={() => props.clicked(props.selectedYear, props.selectedMonth, z)}>
              <ul className={'sixth'}>
                {w}
                {eventsOnDay.map(event => (
                    <div className='important' key={event.id}>{event.title.substring(0, 15)} - {event.createdBy}</div>
                ))}
                {usersOnDay.map(event => (
                    <div className={event.createdBy} key={event.id}>{event.createdBy}</div>
                ))}
              </ul>
              </div>
            </React.Fragment>
          ); 
      }} 
    }
    

    //Date sizing:
    let dateholders = [];
    const [width, setWidth] = useState(window.innerWidth);
    const breakpoint = 600;
    
      if (width > breakpoint) {
        (dateholders.push(
          <React.Fragment>
                <h3>Sunday</h3>
                <h3>Monday</h3>
                <h3>Tuesday</h3>
                <h3>Wednesday</h3>
                <h3>Thursday</h3>
                <h3>Friday</h3>
                <h3>Saturday</h3>
          </React.Fragment>
        ))
      }else{
      (dateholders.push(
        <React.Fragment>
            <h6>Sun</h6>
            <h6>Mon</h6>
            <h6>Tue</h6>
            <h6>Wed</h6>
            <h6>Thu</h6>
            <h6>Fri</h6>
            <h6>Sat</h6>
        </React.Fragment>
      ))
      }
    
    return (
        <React.Fragment>
            <div className = 'daysOfWeek'>
              {dateholders}
            </div>
            <div className="calendarGridContainer">
                <div className="row">{calendarRow1}</div>
                <div className="row">{calendarRow2}</div>
                <div className="row">{calendarRow3}</div>
                <div className="row">{calendarRow4}</div>
                <div className="row">{calendarRow5}</div>
                <div className="row">{calendarRow6}</div>

            </div>
        </React.Fragment>

    );
  }
  
  export default CalendarGrid;