import React, { useState, useEffect } from 'react';

function TaskDetail(props) {

    return(
        <div className='todo-list'>
            {props.todos && props.todos.description}
        </div>
    )
}

export default TaskDetail;