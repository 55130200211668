import React, {useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { API } from '../../API_Service';
import { useCookies} from 'react-cookie'


function ShoppingList(props) {

    const [token, setToken, deleteToken] = useCookies(['higel-token']);        
    const [magnetID] = useCookies(['currentMagnetID']);

    //// Tie in to group number////
    const [group] = useState(magnetID['magnetID']);
    const [currentMagnet] = useCookies(['currentMagnet']);


    const editClicked = shopping => {
        props.editClicked(shopping)
    }

    const deleteClicked = shop => {
        if(props.groceryFlag) 
        {API.deleteGrocery(shop.id, token)
            .then(() => props.deleteClicked(shop))
            .catch(err => console.error(err))} 
        else
        {API.deleteShopping(shop.id, token)
        .then(() => props.deleteClicked(shop))
        .catch(err => console.error(err))
    }}

    return(
        <div className='todo-list'>
            <div key='title' className='listItemShop'> 
                <div className='titleShop'>Item</div>
                <div className='categoryShop'>Category</div>
                <div className='storeShop'>Store</div>
            </div>
              {props.shopping && props.shopping.map(shopping => {
              return <React.Fragment>
                        <div key={shopping.id} className='listItemShop'> 
                            <div className='titleShop'>{shopping.title}</div>
                            <div className='categoryShop'>{shopping.category}</div>
                            <div className='storeShop'>{shopping.store}</div>
                            &nbsp;<FontAwesomeIcon icon={faEdit} onClick={() => editClicked(shopping)}/>
                            &nbsp;<FontAwesomeIcon icon={faTrash} onClick={() => deleteClicked(shopping)}/>
                        </div>
                    </React.Fragment>
              })}
          </div>
    )
}

export default ShoppingList;