import React, { useState, useEffect, useContext } from 'react';
import {API} from './API_Service'
import { useCookies } from 'react-cookie';
import './CSS/Auth.css'

function Auth() {

    const [username, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [isLoginView, setIsLoginView] = useState(true);
    const [errorMSG, setErrorMSG] = useState('');

    const [token, setToken] = useCookies(['higel-token']);
    const [magnet, setMagnet] = useCookies(['magnetName'])
    const [idToken, setIdToken] = useCookies(['magnetToken'])
    const [currentMagnet, setCurrentMagnet, deleteCurrentMagnet] = useCookies('');
    const [magnetID, setMagnetID, deleteMagnetID] = useCookies([]);

    
    useEffect( () => {
        if(token['higel-token']) window.location.href = '/home';
    }, [token])

    const loginClicked = () => {
        API.loginUser({ username, password })
            .then(resp => {
                // Check if the response contains a valid token, then process all the tokens and set the current token for the opening magnet
                if (resp.token) {
                    setToken('higel-token', resp.token);
                    setMagnet('magnetName', resp.magnetName);
                    setIdToken('magnetToken', resp.magnetToken);
                    const fillArray = resp.magnetName[0];
                    setCurrentMagnet('currentMagnet', fillArray[0]);
                    setMagnetID('magnetID', fillArray[1]);
                } else {
                    setErrorMSG('Invalid entry, please try again.'); 
                    // Handle other cases if needed
                }
            })
            .catch(err => console.log(err))
    }

    

    const registerClicked = () => {
        API.registerUser({username, password})
        .then( () => loginClicked()) 
        .catch(err => console.log(err));
    }

    return(
    <div className='AuthContainer'>
        <img className="backgroundImage" src={"/loginBackground.jpeg"} alt={"Error"} />

        <div className='Authorization'>
            <label htmlFor="username">Username</label><br/>
            <input id='username' type="text" name="username" value={username}  
                onChange={ evt => setUserName(evt.target.value)}/><br/>
            <label htmlFor="password">Password</label><br/>
            <input type="password" name="password" id="password" value={password}  
                onChange={ evt => setPassword(evt.target.value)}/><br/>
            
            
                { isLoginView ? 
                <button onClick={loginClicked}> Login </button>             
                :
                <button onClick={registerClicked}> Register </button>             
                }
            { isLoginView ? 
            <p onClick={() => setIsLoginView(false)}> New user? Registered here!</p> 
            :
            <p onClick={() => setIsLoginView(true)}> Already have an account? Login here!</p> 
            }
            { errorMSG ?
                <p className='error'>{errorMSG}</p>
                :
                null
            }

        </div>
    </div>
    )
}

export default Auth;