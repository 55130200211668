import React, { useState, useEffect } from 'react';
import {API} from '../../API_Service';
import {useCookies} from 'react-cookie'

function UpdateTask(props) {

    //update all the use states to match current models. Consider some error checking processes.

    const [title, setTitle] = useState('')
    const [description, setDescription]= useState('')
    const [startDate, setStartDate]= useState('')
    const [endDate, setEndDate]= useState('')
    const [time, setTime]= useState('')
    const [createdBy, setCreatedBy]= useState('')
    const [additionalPeople, setAdditionalPeople]= useState('')
    const [important, setImportant]= useState('')
    const [token] = useCookies(['higel-token'])
    const [currentMagnet] = useCookies(['currentMagnet']);
    const [magnetID] = useCookies(['currentMagnetID']);

    //// Tie in to group number////
    const [group] = useState(magnetID['magnetID']);


    //write to api and includes a refresh function
    const newEvent = () => {
        API.createEvent({title, description, time, startDate, endDate, additionalPeople, createdBy, group}, token)
        .then(resp => props.pushEvent(resp))
        .catch(err => console.log(err));
        };

    const cancelCreateEvent = () => {
        props.cancelCreate(null)
        };
    const startDateSetting = (startDate) => {
        const selectedDate = new Date(startDate);
        const isoDate = selectedDate.toISOString().split('T')[0]; // Extracting YYYY-MM-DD part
        setStartDate(isoDate)
        };

    const endDateSetting = (endDate) => {
        const selectedDate = new Date(endDate);
        const isoDate = selectedDate.toISOString().split('T')[0]; // Extracting YYYY-MM-DD part
        setEndDate(isoDate)
        };

    

   return(
        <div className='eventAdd'>
            <label htmlFor="title">Title</label><br/>
            <input type="text" name="title" id="title" value={title}  
                onChange={ evt => setTitle(evt.target.value)}/><br/>

            <label htmlFor="description">Description</label><br/>
            <input type="text" name="description" id="description" value={description}  
                onChange={ evt => setDescription(evt.target.value)}/><br/>

            <label htmlFor="year">Start Date</label><br/>
            <input type="date" name="year" id="year" value={null}  
                onChange={ evt => startDateSetting(evt.target.value)}/><br/>

            <label htmlFor="year">End Date</label><br/>
            <input type="date" name="year" id="year" value={null}  
                onChange={ evt => endDateSetting(evt.target.value)}/><br/>

            <label htmlFor="time">Time</label><br/>
            <input type="time" name="time" id="time" value={time}  
                onChange={ evt => setTime(evt.target.value)}/><br/>

            <label htmlFor="createdBy">Scheduled by:</label><br/>
            <input type="text" name="createdBy" id="createdBy" value={createdBy}  
                onChange={ evt => setCreatedBy(evt.target.value)}/><br/>

            <label htmlFor="additionalPeople">Additional People</label><br/>
            <input type="text" name="additionalPeople" id="additionalPeople" value={additionalPeople}  
                onChange={ evt => setAdditionalPeople(evt.target.value)}/><br/>

            <label htmlFor="important">Display event on Calendar?</label><br/>
            <input type="checkbox" name="important" id="important" value={important}  
                onChange={ evt => setImportant(evt.target.value)}/><br/>
            
            <button onClick={ evt => newEvent()}> Create </button>
            <button onClick={ evt => cancelCreateEvent()}> Cancel </button>
            
        </div>
    )
}

export default UpdateTask;

