import React, { useState, useEffect } from 'react';
import '../CSS/Shopping.css';
import ShoppingList from './subcomponents/ShoppingList.js';
import ShoppingDetail from './subcomponents/ShoppingDetail.js';
import UpdateShopping from './subcomponents/UpdateShopping.js';
import MagnetChoice from './subcomponents/MagnetChoice.js';
import {useCookies} from 'react-cookie';
import {API} from '../API_Service.js'


function Grocery() {

  const route ='https://refridgeratordoor-b97c7e77f041.herokuapp.com/'
  // const route ='http://127.0.0.1:8000/'

  const [shopping, setShopping] = useState([]);
  const [selectedShopping, setSelectedShopping] = useState(null);
  const [editShopping, setEditShopping] = useState(null);
  const [token, setToken, deleteToken] = useCookies(['higel-token']);
  const [currentMagnet] = useCookies(['currentMagnet']);




  //Flags that this should pass grocery API to the components.
  const [groceryFlag, setGroceryFlag] = useState(true)

  useEffect( () =>{
    fetch(`${route}api/grocery/?group=${currentMagnet['currentMagnet']}`,{
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token['higel-token']}`
      }
    }).then(resp  => resp.json())
    .then(resp => setShopping(resp))
    .catch(error => console.log(error))
  }, [token, currentMagnet])

  useEffect( () => {
    if(!token['higel-token']) window.location.href = '/';
}, [token])


  const shoppingClicked = shopping => {
    setSelectedShopping(shopping);
  }

  const loadShopping = shopping => {
    setSelectedShopping(shopping);
  }

  const editClicked = shopping => {
    setEditShopping(shopping);
  }

  const updatedShop = prevShopping => {
     const updatedShopping = shopping.map(t =>{
      if (t.id === prevShopping.id) {
        return prevShopping;
      }
        return t;
    })
    setShopping(updatedShopping);
    setEditShopping(null);
  }

  const createShopping = () => {
   setEditShopping({title:'', category:'', store:''});
   setSelectedShopping(null)
 }

  const newShopping = shop => {
    const newShop = [...shopping,shop];
    setShopping(newShop);
    setEditShopping(null)
  }

  const deleteClicked = (shop) => {
    const updatedShopping = shopping.filter(t => t.id !== shop.id);
    setShopping(updatedShopping);
  }

  return (
    <div className="Shop-App">
          <header className="App-header">
            <MagnetChoice />
            <h1>Grocery List</h1>
          </header>
          { editShopping ? 
          <UpdateShopping 
          groceryFlag={groceryFlag}
          shopping={editShopping} 
          updatedShop={updatedShop} 
          newShopping={newShopping}/>
          :
          null}
      <ShoppingDetail 
      groceryFlag={groceryFlag}
      shopping={selectedShopping} />
      <hr className='lineShop'/>
      <div className='rowShop'>
        <div className='columnShop'>
          <ShoppingList 
          groceryFlag={groceryFlag}
          shopping={shopping} 
          shoppingClicked={shoppingClicked} 
          editClicked={editClicked}
          deleteClicked={deleteClicked}/> 
          <button onClick={createShopping}> Add Item </button>
        </div>
        <div className='columnShop'>
          
        </div>
        <div className='columnShop'>

        </div>
      </div>
    </div>
  );
}

export default Grocery;
