import React, { useState, useEffect } from "react";
import CalendarControl from "./subcomponents/MyCalendar";
import CalendarGrid from "./subcomponents/CalendarGrid";
import NewEvent from "./subcomponents/NewEvent";
import CalendarDay from "./subcomponents/CalendarDay";
import MagnetChoice from "./subcomponents/MagnetChoice";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse } from '@fortawesome/free-solid-svg-icons';
import {useCookies} from 'react-cookie';


function CalendarApp () {

const route ='https://refridgeratordoor-b97c7e77f041.herokuapp.com/'
// const route ='http://127.0.0.1:8000/'

  const [calendarEvents, setCalendarEvents] = useState([]);
  const [token, setToken, deleteToken] = useCookies(['higel-token']);        
  const [magnetID] = useCookies(['currentMagnetID']);

  //// Tie in to group number////
  const [group] = useState(magnetID['magnetID']);
  const [currentMagnet] = useCookies(['currentMagnet']);


  useEffect( () => {
    if(!token['higel-token']) window.location.href = '/';
}, [token])


  useEffect(() => {
    fetch(`${route}api/calendar/?group=${currentMagnet['currentMagnet']}`,{
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token['higel-token']}`
      }
    })
          .then(response => response.json())
          .then(resp => setCalendarEvents(resp))
          .catch(error => console.error('Error fetching today\'s date:', error));
  }, [token, currentMagnet, group]);

  const [selectedMonth, setSelectedMonth] = useState((new Date().getMonth()))
  const [selectedYear, setSelectedYear] = useState((new Date().getFullYear()))
  const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const [displayMonth, setDisplayMonth] = useState(monthNames[new Date().getMonth()])
  const [newEvent, setNewEvent] = useState('')
  const[selectedDate, setSelectedDate] = useState('')

  const nextMonth = selectedMonth => {
    setSelectedMonth(selectedMonth)
    setDisplayMonth(monthNames[selectedMonth])
  }
  const previousMonth = selectedMonth => {
    setSelectedMonth(selectedMonth)
    setDisplayMonth(monthNames[selectedMonth])
  }

  const nextYear = selectedYear => {
    setSelectedYear(selectedYear)
  }
  const previousYear = selectedYear => {
    setSelectedYear(selectedYear)
  }

  const createNewEvent = () => {
    setNewEvent({title:'', description:'',startDate:'',endDate:'',createdBy:'',additionalPeople:'',})
  }

  const pushEvent = event => {
    const pushEvent = [...calendarEvents,event];
    setCalendarEvents(pushEvent);
    setNewEvent(null)
  }

  const cancelCreate = event => {
    setNewEvent(null)
  }

  const clicked = (year, month, day) => {
    const javascriptDate = new Date(year, month, day);
    const formattedDate = javascriptDate.toISOString().split('T')[0];
    if (javascriptDate.getMonth() === selectedMonth) {
    setSelectedDate(formattedDate)
    console.log(formattedDate);} else {alert("Choose a day within the current month or switch to the month you're trying to select.")}
  }

  const returnButton = () => {
    setSelectedDate(null);
  }



    return(
        <React.Fragment>
        <div className="CalendarContainer">
          <header className="App-header">
            <MagnetChoice />
          </header>
          <main>
          {selectedDate ? null:
            <div>
              <CalendarControl 
              calendarEvents={calendarEvents} 
              nextMonth = {nextMonth} 
              previousMonth = {previousMonth} 
              nextYear = {nextYear} 
              previousYear = {previousYear} 
              selectedMonth = {selectedMonth}
              displayMonth = {displayMonth}
              selectedYear = {selectedYear}
              />
              <button className="newEventsButton" onClick={createNewEvent}> Create Event</button>
            </div>
            }
            {newEvent ? 
            <NewEvent 
            createNewEvent = {createNewEvent}
            newEvent={newEvent}
            displayMonth = {displayMonth}
            selectedYear = {selectedYear}
            pushEvent = {pushEvent}
            cancelCreate = {cancelCreate}
            /> : null
            }

          </main>
          {selectedDate ?
          <CalendarDay 
          returnButton = {returnButton}
          selectedDate = {selectedDate}
          calendarEvents={calendarEvents}
          pushEvent = {pushEvent}/> 
          :
          <CalendarGrid calendarEvents={calendarEvents} 
          selectedMonth = {selectedMonth}
          selectedYear = {selectedYear}
          clicked = {clicked}
          />
          }
        </div>
        </React.Fragment>
    )
}

export default CalendarApp;