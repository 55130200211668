import React, {useState, useEffect} from "react";
import  "../../CSS/Calendar.css";
import {API} from '../../API_Service';
import { useCookies } from "react-cookie";

function CalendarDay(props) {


    const route ='https://refridgeratordoor-b97c7e77f041.herokuapp.com/'
// const route ='http://127.0.0.1:8000/'


        //update all the use states to match current models. Consider some error checking processes.

        const [title, setTitle] = useState('')
        const [description, setDescription]= useState('')
        const [startDate, setStartDate]= useState(props.selectedDate)
        const [endDate, setEndDate]= useState(props.selectedDate)
        const [time, setTime]= useState('12:00:00')
        const [createdBy, setCreatedBy]= useState('')
        const [additionalPeople, setAdditionalPeople]= useState('')
        const [important, setImportant]= useState(false)
        const [token] = useCookies(['higel-token']) 
        const [magnetID] = useCookies(['currentMagnetID'])
        const [userOptions, setUserOptions] = useState([])

        //// Tie in to group number////
        const [group] = useState(magnetID['magnetID'])
    
        // get all the users for the dropdown lists////
        useEffect(() => {
            API.getUsers(group, token)
              .then(resp => setUserOptions(resp))
              .catch(error => console.log(error))
            }, [group, token])

            console.log(userOptions)

        //write to api and includes a refresh function
        const newEvent = () => {
            API.createEvent({title, description, time, startDate, endDate, additionalPeople, createdBy, important, group}, token)
            .then(resp => props.pushEvent(resp))
            .catch(err => console.log(err));
            };
    
        const startDateSetting = (startDate) => {
            const selectedDate = new Date(startDate);
            const isoDate = selectedDate.toISOString().split('T')[0]; // Extracting YYYY-MM-DD part
            setStartDate(isoDate)
            };
    
        const endDateSetting = (endDate) => {
            const selectedDate = new Date(endDate);
            const isoDate = selectedDate.toISOString().split('T')[0]; // Extracting YYYY-MM-DD part
            setEndDate(isoDate)
            };

    const returnToCalendar = evt =>{
        props.returnButton()
    }

    props.calendarEvents.map(event => {
        const javascriptDate = new Date(event.startDate)
        const formattedDate = javascriptDate.toISOString().split('T')[0];
        event.startDate = formattedDate
        const javascriptDate2 = new Date(event.endDate)
        const formattedDate2 = javascriptDate2.toISOString().split('T')[0];
        event.endDate = formattedDate2
    })
    
    const todaysEvents = props.calendarEvents.filter(event => 
        event.startDate <= props.selectedDate && event.endDate >= props.selectedDate);

return <React.Fragment>
    <div className="dayContainer">
        <div className="dayLeft">
            <h1>{props.selectedDate}</h1>
            <button onClick={() => returnToCalendar()}> Return to Calendar View </button>    
            {todaysEvents.map(event => (
                    <div>
                        <hr className="dayHardBreak"/>
                        <h4 className='eventHeader' key={event.id}>Event: {event.title} , 
                            Scheduled by: {event.createdBy} 
                            {event.additionalPeople ? 
                                <h6> Additional People: {event.additionalPeople}</h6> 
                                : null}</h4>
                        <hr className="daySubBreak"/>
                        <h5 className='eventSubHeader' key={event.id}>Start Time: {event.time}</h5>
                        <p className='eventInfo' key={event.id}>{event.description}</p>
                        { event.startDate != event.endDate ? 
                            <p className='eventInfo' key={event.id}> Start: {event.startDate} - End: {event.endDate}</p> 
                            :null}
                        
                    </div>
                ))}
                <hr className="dayHardBreak"/>
        </div>
        <div className="dayRight">
            <label htmlFor="title">Title</label><br/>
            <input type="text" name="title" id="title" value={title}  
                onChange={ evt => setTitle(evt.target.value)}/><br/>

            <label htmlFor="description">Description</label><br/>
            <input type="text" name="description" id="description" value={description}  
                onChange={ evt => setDescription(evt.target.value)}/><br/>

            <label htmlFor="year">Start Date</label><br/>
            <input type="date" name="year" id="year" value={null} defaultValue={props.selectedDate} 
                onChange={ evt => startDateSetting(evt.target.value)}/><br/>

            <label htmlFor="year">End Date</label><br/>
            <input type="date" name="year" id="year" value={null} defaultValue={props.selectedDate} 
                onChange={ evt => endDateSetting(evt.target.value)}/><br/>

            <label htmlFor="time">Time</label><br/>
            <input type="time" name="time" id="time" defaultValue = {'12:00'} 
                onChange={ evt => setTime(evt.target.value)}/><br/>
            
            <label htmlFor="createdBy">Scheduled by:</label>
            <select name='createdBy' onChange={ evt => setCreatedBy(evt.target.value)}>
                <option key='empty' value='Select'>
                    Select</option>
                {userOptions.map(userOption => 
                <option key={userOption.username} value={userOption.username}>
                    {userOption.username}</option>)}    
            </select>

            <label htmlFor="additionalPeople">Additional People</label><br/>
            <input type="text" name="additionalPeople" id="additionalPeople" value={additionalPeople}  
                onChange={ evt => setAdditionalPeople(evt.target.value)}/><br/>

            <label htmlFor="important">Display event on Calendar?</label><br/>
            <input type="checkbox" name="important" id="important" value={important}  
                onChange={ evt => setImportant(evt.target.checked)}/><br/>
            
            <button onClick={ evt => newEvent()}> Create </button>
        </div>
    </div>
    </React.Fragment>

}


export default CalendarDay