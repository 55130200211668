import React,{useEffect, useState} from 'react';
import '../CSS/Home.css';
import { useCookies } from 'react-cookie';
import calendarIcon from '../images/CalendarIcon.png';
import favoriteIcon from '../images/FavoriteIcon.png';
import groceryIcon from '../images/GroceryIcon.png';
import settingsIcon from '../images/SettingIcon.png';
import shoppingIcon from '../images/ShoppingIcon.png';
import toDoIcon from '../images/ToDoIcon.png';


function Home() {

  const [currentMagnet, setCurrentMagnet, deleteCurrentMagnet] = useCookies([]);
  const [magnetID, setMagnetID, deleteMagnetID] = useCookies([]);
  const [displayMagnet, setDisplayMagnet] = useState(currentMagnet['currentMagnet']);
  const [token, setToken, deleteToken] = useCookies(['higel-token']);

    const goToCalendar = () => {
        window.location.href = '/calendar'
    }
    
    const goToToDo = () => {
        window.location.href = '/todo'
    }
    
    const goToShopping = () => {
        window.location.href = '/Shopping'
    }

    const goToGrocery = () => {
        window.location.href = '/Grocery'
    }

    const goToFavorites = () => {
        window.location.href = '/Favorites'
    }

    useEffect( () => {
      if(!token['higel-token']) window.location.href = '/';
  }, [token])

  const logOut = () => {
    deleteToken(['higel-token']);
    deleteCurrentMagnet(['currentMagnet']);
  }

  //Sets dictionary because magnet names are for filters/display and id is for POST/PUT requests//
  var magnetDictionary = {}

  token['magnetName'].map( mag => {
    magnetDictionary[mag[0]] = mag[1]
  })

  // diplays the magnet choices. Need a use state to update the actual display state.
  const handleChange = (event) => { 
    setCurrentMagnet('currentMagnet', event.target.value);
    setMagnetID('magnetID', magnetDictionary[event.target.value])
    setDisplayMagnet(event.target.value); 
  }; 

  return (
    <React.Fragment>
      
    <div className="Home">
    <img className="HomeBackground" src={"/HomeBackground.jpeg"} alt={"Error"} />
      <header className="HomeHeader1">
          {/* cycle through all the magnet names */}
        <select value={displayMagnet} onChange={handleChange}> 
            {token['magnetName'].map( mag => 
            <option key={mag[1]} value={mag[0]}>{mag[0]}</option>)}
        </select> 
        

          <h4 className='headerOne'> 
            The Refridgerator Door
          </h4>
        
          <p onClick={ () => logOut() }>Logout</p>
        
      </header>
      <div className='iconRow'>
        <div className='iconContainer'>
          <img src={calendarIcon} className='icon' onClick={goToCalendar} />
            <p className='title'>Calendar</p>
        </div>
        <div className='iconContainer'>
            <img src={groceryIcon} className='icon' onClick={goToGrocery} />
            <p className='title'>Grocery List</p>
        </div>
        <div className='iconContainer'>
            <img src={shoppingIcon} className='icon' onClick={goToShopping} />
            <p className='title'>Shopping List</p>
        </div>
        <div className='iconContainer'>
            <img src={toDoIcon} className='icon' onClick={goToToDo} />
            <p className='title'>To-Do List</p>
        </div>
        <div className='iconContainer'>
            <img src={favoriteIcon} className='icon' onClick={goToFavorites} />
            <p className='title'>Our Favorites</p>
        </div>
      </div>
    </div>
    </React.Fragment>
  );
}

export default Home;
