import React, { useState, useEffect } from 'react';
import '../CSS/Favorites.css';
import '../CSS/MagnetChoice.css';
import MagnetChoice from './subcomponents/MagnetChoice.js';
import UpdateFavorites from './subcomponents/UpdateFavorites.js';
import {useCookies} from 'react-cookie';
import {API} from '../API_Service.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faHeartCirclePlus } from '@fortawesome/free-solid-svg-icons';



function Favorites() {

  // const route ='https://refridgeratordoor-b97c7e77f041.herokuapp.com/'
  const route ='http://127.0.0.1:8000/'

  const [token, setToken, deleteToken] = useCookies(['higel-token']);
  const [currentMagnet] = useCookies(['currentMagnet']);
  const [magnetID] = useCookies(['currentMagnetID']);

  const [favorites, setFavorites] = useState([]);
  const [selectedFavorites, setSelectedFavorites] = useState([]);
  const [editFavorites, setEditFavorites] = useState(null);
  //// Tie in to group number////
  const [group] = useState(magnetID['magnetID']);

  useEffect( () => {
    API.getFavs(currentMagnet['currentMagnet'],token)
    .then(resp => {setFavorites(resp)});
  }, [currentMagnet])

      // Add the auto update feature for new items added to the category group
      const category = ['All'];
      const [categoryFilter, setCategoryFilter] = useState('All');
      let i = 0;
      //Have to for each loop to get the items in the category group
      favorites.map(fave => {
      category.push(fave.category);
      })


  useEffect( () => {
    if(!token['higel-token']) window.location.href = '/';
}, [token])


const favoriteClicked = favorites => {
  setSelectedFavorites(favorites);
}

const loadFavorites = favorites => {
  setSelectedFavorites(favorites);
}

const editClicked = favorite => {
  console.log(favorite);
  setEditFavorites(favorite);
}

const updatedFavorites = prevFavorites => {
   const updatedFavorites = favorites.map(t =>{
    if (t.id === prevFavorites.id) {
      return prevFavorites;
    }
    //This may be a problem with new items added to the category group
    category.push(t.category);
      return t;
  })
  setFavorites(updatedFavorites);
  setEditFavorites(null);
}

const createFavorites = () => {
 setEditFavorites({title:'', category:'', info:''});
 setSelectedFavorites(null)
}

const newFavorites = fave => {
  const newFave = [...favorites,fave];
  category.push(fave.category);
  setFavorites(newFave);
  setEditFavorites(null)
}

const cancelClick = () => {
  setEditFavorites(null)
}

const deleteClicked = (fave) => {
  const updatedFavorites = favorites.filter(t => t.id !== fave.id);
  setFavorites(updatedFavorites);
}

const catFilter = (event) => {
  setCategoryFilter(event.target.value);
}

  return (
    <React.Fragment>
      <div className='Favorites-App'>
          <div className="Fav-Header">
            <MagnetChoice />
            <h2>Favorites</h2>
          </div>
          <label>Category Filter:</label>
            &nbsp;
            <select className='categoryFilter' value={categoryFilter} onChange={catFilter}> 
                    {category.map( mag => 
                    <option key={mag} value={mag}>{mag}</option>)}
            </select> 
          <div className='newFavorites' onClick={() => createFavorites()}>
            <button>Create new favorite
            &nbsp;
            <FontAwesomeIcon icon={faHeartCirclePlus} />
            </button>


          </div>
        { editFavorites ? 
        <UpdateFavorites 
        favorites={editFavorites} 
        updatedFavorites={updatedFavorites} 
        newFavorites={newFavorites}
        cancelClick={cancelClick}/>
        :
        null}

          {/* title list  */}
        <div className='rowFav'>
          <div className='columnFT1'>
              <p>Title</p>
          </div>
          <div className='columnFT2'>
              <p>Category</p>
          </div>
          <div className='columnFT3'>
              <p>Info</p>
          </div>   
       
        </div>
        <hr className='line'/>

        {/* data list */}
      {categoryFilter === 'All' ? favorites.map(fav => (
        <div className='lineDiv'>
          <div className='rowFav'>
            
                &nbsp;<FontAwesomeIcon icon={faEdit} onClick={() => editClicked(fav)}/>
                &nbsp;<FontAwesomeIcon icon={faTrash} onClick={() => deleteClicked(fav)}/>

            <div className='columnF1'>
                <p>{fav.title}</p>
            </div>
            <div className='columnF2'>
                <p>{fav.category}</p>
            </div>
            <div className='columnF3'>
                <p>{fav.info}</p>
          </div> 
          </div>   
          <hr className='line'/>  
              
        </div>)) : 
        favorites.filter(f => f.category == categoryFilter).map(fav => (
          <div className='lineDiv'>
            <div className='rowFav'>
              
                  &nbsp;<FontAwesomeIcon icon={faEdit} onClick={() => editClicked(fav)}/>
                  &nbsp;<FontAwesomeIcon icon={faTrash} onClick={() => deleteClicked(fav)}/>
  
              <div className='columnF1'>
                  <p>{fav.title}</p>
              </div>
              <div className='columnF2'>
                  <p>{fav.category}</p>
              </div>
              <div className='columnF3'>
                  <p>{fav.info}</p>
            </div> 
            </div>   
            <hr className='line'/>  
                
          </div>))
        
        }
      

      </div>
    </React.Fragment>
  );
}

export default Favorites;
