import React, { useState, useEffect } from 'react';
import {API} from '../../API_Service';
import { useCookies } from 'react-cookie';

function UpdateShopping(props) {

    const [title, setTitle] = useState(props.shopping.title);
    const [category, setCategory] = useState(props.shopping.category);
    const [store, setStore] = useState(props.shopping.store);
    const [token] = useCookies(['higel-token'])
    const [currentMagnet] = useCookies(['currentMagnet']);
    const [magnetID] = useCookies(['currentMagnetID']);

    //// Tie in to group number////
    const [group] = useState(magnetID['magnetID']);


    useEffect(() => {
        setTitle(props.shopping.title);
        setCategory(props.shopping.category);
        setStore(props.shopping.store);
    }, [props.shopping]);

    const saveShopping = () => {
        if(props.groceryFlag) 
        {API.updateGrocery(props.shopping, {title, category, store, group}, token)
        .then(resp => props.updatedShop(resp))
        .catch(err => console.log(err));
        }
        else
        {API.updateShopping(props.shopping, {title, category, store, group}, token)
        .then(resp => props.updatedShop(resp))
        .catch(err => console.log(err));
        };}

    const newShopping = () => {
        if(props.groceryFlag) 
        {API.createGrocery({title, category, store, group}, token)
        .then(resp => props.newShopping(resp))
        .catch(err => console.log(err));
        }
        else
        {API.createShopping({title, category, store, group}, token)
        .then(resp => props.newShopping(resp))
        .catch(err => console.log(err));
        };}

    return(
        <div className='todo-list'>
            <label htmlFor="title">Title</label><br/>
            <input type="text" name="title" id="title" value={title}  
                onChange={ evt => setTitle(evt.target.value)}/><br/>
            <label htmlFor="Category">Category</label><br/>
            <input type="text" name="Category" id="Category" value={category}  
                onChange={ evt => setCategory(evt.target.value)}/><br/>
            <label htmlFor="Store">Store</label><br/>
            <input type="text" name="Store" id="Store" value={store}  
                onChange={ evt => setStore(evt.target.value)}/><br/>
            
            {props.shopping.id ? 
            <button onClick={ evt => saveShopping()}> Save </button> 
            :
            <button onClick={ evt => newShopping()}> Create </button>
            }
        </div>
    )
}

export default UpdateShopping;